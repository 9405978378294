import React, { createContext, useContext, useState } from "react";
import { useChat } from "../hooks/useChat";
import { ChatItem, Source, Thread } from "../types/types";

interface ChatContextProps {
  threads: Thread[];
  changeThread: (thread: Thread | null, fetch?: boolean) => void;
  selectedThread: Thread | null;
  sendQuery: (payload: { query?: string; [key: string]: any }) => void;
  abortQuery: () => void;
  isLoadingQuery: boolean;
  chatItems: ChatItem[];
  fetchMessages: (threadId: string) => void;
  feedbackEndpoint: string;
  selectedSource: Source | null;
  changeSelectedSource: (source: Source | null) => void;
  setSelectedSource: (source: Source | null) => void;
  setFilters: (filters: any) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider: React.FC<{
  children: React.ReactNode;
  threadsEndpoint: string;
  messagesEndpoint: string;
  chatEndpoint: string;
  feedbackEndpoint: string;
}> = ({
  children,
  threadsEndpoint,
  messagesEndpoint,
  chatEndpoint,
  feedbackEndpoint,
}) => {
  const chat = useChat(threadsEndpoint, messagesEndpoint, chatEndpoint);
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);

  return (
    <ChatContext.Provider
      value={{
        ...chat,
        feedbackEndpoint,
        selectedSource,
        setSelectedSource,
        changeSelectedSource: setSelectedSource,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
