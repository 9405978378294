import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./Filters.module.scss";
import addInfo from "../../../../../../data/addInfo.json";

interface FilterProps {
  criterias: any; // The filter options from the API
  filters: any; // The currently selected filters
  setFilters: (filters: any) => void; // Function to update filters
}

const FilterLabel = ({
  label,
  tooltip,
}: {
  label: string;
  tooltip: string;
}) => (
  <>
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-${label}`}>{tooltip}</Tooltip>}
      delay={{ show: 150, hide: 200 }}
    >
      <span className={styles.infoIconWrapper}>
        <span className={styles.infoIcon}>
          <i className="bi bi-info-circle"></i>
        </span>
      </span>
    </OverlayTrigger>
    <Form.Label style={{ fontSize: "0.95em" }}>{label}</Form.Label>
  </>
);

const Filters: React.FC<FilterProps> = ({ criterias, filters, setFilters }) => {
  // Helper function to create MultiSelect options
  const createOptions = (items: string[] = []) =>
    items.map((item) => ({ label: item, value: item }));

  return (
    <div className="d-flex flex-column width-full p-2">
      <Form>
        {/* Sub Industry Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project name"
            tooltip="Choose the name/s of your projects"
          />
          <MultiSelect
            options={createOptions(criterias?.subIndustry)}
            value={filters?.subIndustry || []}
            onChange={(e) => setFilters({ ...filters, subIndustry: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Project Name Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Folder"
            tooltip="Choose the folder of the documents you want to search"
          />
          <MultiSelect
            options={createOptions(criterias?.projectName)}
            value={filters?.projectName || []}
            onChange={(e) => setFilters({ ...filters, projectName: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
      </Form>
      <hr className="my-3" />
      <div className="text-muted small">
        <p className="mb-0" style={{ fontSize: "1.05em", padding: "8px" }}>
          <strong>{addInfo.title}</strong>
        </p>
        {addInfo.items.map((item, index) => (
          <p key={index} className="mb-2" style={{ fontSize: "0.95em" }}>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-item-${index}`}>{item.tooltip}</Tooltip>
              }
            >
              <span className={styles.infoIcon} style={{ color: "#007bff" }}>
                <i className="bi bi-info-circle"></i>
              </span>
            </OverlayTrigger>{" "}
            {item.isBold
              ? item.text
                  .split(new RegExp(`(${item.isBold.join("|")})`, "g"))
                  .map((part, i) =>
                    item.isBold.includes(part) ? <b key={i}>{part}</b> : part
                  )
              : item.text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Filters;
