import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import Alert from "react-bootstrap/Alert"; // Import for error messages
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader";
import { useChatContext } from "../../../contexts/ChatContext";
import useAsset from "../../../hooks/useAsset";
import { isString } from "../../../types/typeGuards";
import { Source } from "../../../types/types";
import log from "../../../utils/logger";
import styles from "./SourceViewer.module.scss";

const SourceViewer = () => {
  const { fetchAsset, error: assetError } = useAsset(); // Destructure error from useAsset
  const { selectedSource } = useChatContext();
  const [sourceImage, setSourceImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false); // New loading state for PDF
  const [error, setError] = useState<string | null>(null); // Declare error state

  useEffect(() => {
    if (!selectedSource) {
      setSourceImage("");
      setError(null);
      setShowModal(false);
      setLoadingPdf(false);
    }
  }, [selectedSource]);

  const openPdfAtPage: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event: MouseEvent) => {
      if (selectedSource) {
        setLoadingPdf(true); // Set loading state to true
        const asset = {
          containerName: "data-deal",
          folder: "pdf",
          sourceName: `${selectedSource.fileName}`,
          extension: "pdf",
          mediaType: "application/pdf",
          path: `${selectedSource?.filePath}`,
        };
        const url = await fetchAsset(asset);
        const pageNumber = isString(selectedSource.page)
          ? parseInt(selectedSource.page)
          : selectedSource.page;

        // Open the PDF in a new window with specified dimensions
        const pdfWindow = window.open(
          `${url}#page=${pageNumber + 1}`,
          "_blank",
          "width=800,height=600"
        ); // Adjust width and height as needed
        if (pdfWindow) {
          pdfWindow.focus(); // Focus on the new window
        }

        setLoadingPdf(false); // Reset loading state after opening
      }
    },
    [fetchAsset, selectedSource]
  );

  const getSourceImage = useCallback(
    async (source: Source | null) => {
      log.debug("Getting source image", source);
      if (source) {
        setError(null); // Reset error state
        const asset = {
          containerName: "data-deal",
          folder: "img",
          sourceName: `${source?.fileName}_${source?.page}`,
          extension: "jpg",
          mediaType: "application/pdf",
        };
        const url = await fetchAsset(asset);
        if (!url) {
          setError("Failed to load image");
          return;
        }
        setSourceImage(url);
      }
    },
    [fetchAsset, setSourceImage]
  );

  useEffect(() => {
    // Reset state when a new source is selected
    setSourceImage(""); // Reset the source image
    setError(null); // Clear any previous error
    getSourceImage(selectedSource); // Fetch the new source image
  }, [selectedSource, getSourceImage]);

  return (
    <div className="p-3 overflow-y-scroll custom-sidebar h-100 d-flex flex-column">
      {selectedSource ? (
        <>
          {sourceImage ? (
            <img
              src={sourceImage}
              className="mx-auto my-auto"
              style={{ width: "100%", cursor: "pointer" }}
              alt="Source"
              onClick={() => setShowModal(true)}
            />
          ) : error ? (
            <div className={styles.alertContainer}>
              <Alert variant="danger">{error}</Alert>
            </div>
          ) : (
            <Loader text={"Getting image..."} width="2rem" height="2rem" />
          )}
          <Button
            variant="outline-primary"
            size="sm"
            style={{ textAlign: "left" }}
            onClick={openPdfAtPage}
            disabled={loadingPdf} // Disable button while loading
          >
            {loadingPdf ? (
              <Loader
                text={"Opening PDF..."}
                width="1rem"
                height="1rem"
                orientation="horizontal"
              /> // Example usage
            ) : error ? (
              <span className={styles.buttonText}>Failed to load document</span>
            ) : (
              <span className={styles.buttonText}>
                Open PDF at page {Number(selectedSource.page) + 1}
              </span>
            )}
          </Button>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="xl"
            centered
          >
            <Modal.Header style={{ justifyContent: "center" }} closeButton>
              <b
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={openPdfAtPage}
              >
                {selectedSource.fileName}
              </b>
              <i>[p.{selectedSource.page}]</i>
            </Modal.Header>
            <Modal.Body>
              <Image src={sourceImage} fluid />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <p
          className="mx-auto my-auto text-body-secondary"
          style={{ textAlign: "center" }}
        >
          Select a source to view slide
        </p>
      )}
    </div>
  );
};

export default SourceViewer;
