import { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useChatContext } from "../../../contexts/ChatContext";
import useAsset from "../../../hooks/useAsset";
import { isString } from "../../../types/typeGuards";
import { Source } from "../../../types/types";
import log from "../../../utils/logger";

const SourceViewer = () => {
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const { selectedSource } = useChatContext();
  const { fetchAsset, isLoading } = useAsset();

  const getPdf = useCallback(
    async (source: Source | null) => {
      log.debug("Getting source image", source);
      if (source) {
        try {
          const asset = {
            containerName: "finance",
            fileName: `${source.fileName}.pdf`,
            fullPath: `oreima/${source.fileName}.pdf`,
            mediaType: "application/pdf",
          };
          const url = await fetchAsset(asset);
          if (url) {
            const pageNumber = isString(source.page)
              ? parseInt(source.page)
              : source.page;
            setPdfUrl(url + `#page=${pageNumber}`);
          } else {
            throw new Error("Failed to fetch PDF URL");
          }
        } catch (err) {
          log.error("Error fetching PDF:", err);
          setPdfUrl("");
        }
      }
    },
    [fetchAsset]
  );

  useEffect(() => {
    getPdf(selectedSource);
  }, [selectedSource, getPdf]);

  useEffect(() => {
    if (!selectedSource) {
      setPdfUrl("");
    }
  }, [selectedSource]);

  return (
    <Col className="d-flex flex-column h-100">
      {isLoading && (
        <div className="d-flex flex-column h-100">
          <p className="mx-auto my-auto text-body-secondary">Loading PDF...</p>
        </div>
      )}
      {!isLoading && selectedSource && pdfUrl ? (
        <iframe
          title="PDF"
          src={pdfUrl}
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      ) : (
        !isLoading && (
          <div className="d-flex flex-column h-100">
            <p className="mx-auto my-auto text-body-secondary">
              Select a clause to view PDF
            </p>
          </div>
        )
      )}
    </Col>
  );
};

export default SourceViewer;
