import React, { useState } from "react";
import { useChatContext } from "../../contexts/ChatContext";
import { Thread as ThreadType } from "../../types/types";
import styles from "./Threads.module.scss";

interface ThreadProps {
  thread: ThreadType;
}
const Thread: React.FC<ThreadProps> = ({ thread }) => {
  const { changeThread, selectedThread, abortQuery, setFilters } =
    useChatContext();
  const onChangeThread = (thread: ThreadType) => {
    abortQuery();
    changeThread(thread);
    // setFilters({});
  };

  return (
    <div
      className={`${styles.thread} ${
        selectedThread?.id === thread.id && styles.threadActive
      }`}
      onClick={() => onChangeThread(thread)}
    >
      {thread.title}
    </div>
  );
};

interface ThreadsProps {}

const Threads: React.FC<ThreadsProps> = () => {
  const { threads, changeThread, abortQuery } = useChatContext();

  const [search, setSearch] = useState<string>("");

  const onChangeThread = (thread: ThreadType | null) => {
    abortQuery();
    changeThread(thread);
  };

  const filteredThreads = threads.filter((thread) => {
    if (search) {
      return thread.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    } else {
      return true;
    }
  });

  return (
    <div className="d-flex flex-column gap-2 p-2">
      <input
        className={`${styles.searchInput}`}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        placeholder="Search..."
      />
      <div
        className={`${styles.thread} align-self-start`}
        onClick={() => onChangeThread(null)}
      >
        <i className="bi bi-plus-circle-dotted"></i> New thread
      </div>
      {threads.length === 0 ? (
        <div className="align-self-center small">
          <i className="bi bi-info-circle"></i> No chat history found yet...
        </div>
      ) : (
        filteredThreads.map((thread, idx) => (
          <Thread key={idx} thread={thread} />
        ))
      )}
    </div>
  );
};

export default Threads;
